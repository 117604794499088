<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        All Players
      </h3>
      <!-- <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Table</a></li>
            <li class="breadcrumb-item active" aria-current="page">Advanced Table</li>
          </ol>
        </nav> -->
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-modal ref="player-modal" hide-footer :no-close-on-backdrop="true" id="modallg" title="Player Information"
              size="lg" :footer-bg-variant="'info'">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="name" class="font-weight-bold">Player Name</label>
                      <input :value="selectedPlayer.firstName + ' ' + selectedPlayer.lastName" type="text" class="form-control form-control-lg"
                        id="name" placeholder="name">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="email" class="font-weight-bold">Email</label>
                      <input :value="selectedPlayer.email" type="email" class="form-control form-control-lg"
                        id="email" placeholder="email">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="phone" class="font-weight-bold">Phone</label>
                      <input :value="selectedPlayer.phoneNumber" type="text" class="form-control form-control-lg"
                        id="phone" placeholder="phone">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="pname" class="font-weight-bold">Parent's Name</label>
                      <input :value="selectedPlayer.parentFullName" type="text" class="form-control form-control-lg"
                        id="pname" placeholder="pname">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="pemail" class="font-weight-bold">Parent's Email</label>
                      <input :value="selectedPlayer.parentEmail" type="email" class="form-control form-control-lg"
                        id="pemail" placeholder="pemail">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="pnum" class="font-weight-bold">Parent's Phone</label>
                      <input :value="selectedPlayer.parentPhoneNumber" type="text" class="form-control form-control-lg"
                        id="pnum" placeholder="pnum">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="school" class="font-weight-bold">School</label>
                      <input :value="selectedPlayer.school" type="text" class="form-control form-control-lg"
                        id="school" placeholder="school">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="grade" class="font-weight-bold">Grade</label>
                      <input :value="selectedPlayer.grade" type="text" class="form-control form-control-lg"
                        id="grade" placeholder="grade">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="classcode" class="font-weight-bold">Class Code</label>
                      <input :value="selectedPlayer.classCode" type="text" class="form-control form-control-lg"
                        id="classcode" placeholder="classcode">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="state" class="font-weight-bold">State</label>
                      <input :value="selectedPlayer.state" type="text" class="form-control form-control-lg"
                        id="state" placeholder="state">
                    </div>                    
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="city" class="font-weight-bold">City</label>
                      <input :value="selectedPlayer.city" type="text" class="form-control form-control-lg"
                        id="city" placeholder="city">
                    </div>                    
                  </div>
                </div>
              </div>
              <!-- <b-button class="mt-3 subbtn float-end" variant="outline-success" @click="() => {this.selectedJobId = null, this.$refs['job-modal'].hide();}">close</b-button> -->
            </b-modal>
            <h4 class="card-title"></h4>
            <div class="float-right">
              <!-- search field -->
              <b-input v-model="filter" placeholder="Search Player" id="player-search" style="padding: 10px"></b-input>
            </div>
            <b-table :items="items" id="table-list" responsive :per-page="perPage" :current-page="currentPage"
              :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty :filter="filter" striped
              hover>

              <!-- when no item found -->
              <template #empty="">
                <p class="text-muted text-center">{{ noItems }}</p>
              </template>

              <!-- description -->
              <template v-slot:cell(description)="data">
                <span v-b-tooltip.html.top :title="data.item.fullDescription" v-html="data.value"></span>
              </template>


              <template v-slot:cell(action)="data">
                <!-- Actions -->

                <i v-b-modal.modallg v-b-tooltip.html.top title="View Detail" variant="primary"
                  @click="viewDetail(data.item.id)" :ref="'btn' + data.index"
                  class="mr-2 mdi mdi-eye text-success icon-sm"></i>

                <span v-html="data.value"></span>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";


Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  data: function () {
    return {
      sortBy: 'name',
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      filter: "",
      sortable: true,
      fields: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'grade', sortable: true },
        // { key: 'action', sortable: true },
        { key: 'createdAt', sortable: true },
      ],
      items: [],
      noItems: null,
      selectedPlayer: {}
    };
  },
  computed: {
    ...mapGetters(['getPlayers']),
    rows() {
      return this.items.length
    }
  },
  methods: {
    ...mapActions(['setPlayers']),
    setItems(data) {
      data.forEach((element) => {
        let obj = {};
        obj.id = element.id;
        obj.name = element.firstName + element.lastName;
        obj.email = element.email;
        obj.phone = element.phoneNumber;
        obj.grade = element.grade
        obj.createdAt = moment(element.createdAt).format('ll')

        this.items.push(obj);
      });
    },
    viewDetail(id) {
      this.selectedPlayer = {};
      this.selectedPlayer = this.getPlayers.filter((player) => player.id == id)[0];

      console.log('sel', this.selectedPlayer);
    }
  },
  async mounted() {
    await this.setPlayers();
    this.getPlayers.length > 0
      ? this.setItems(this.getPlayers)
      : (this.noItems = "No Player Found.");

    console.log(this.getPlayers)
  }
}
</script>
    